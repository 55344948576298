import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MenyComponent } from '../meny/meny.component';
import { GlobalService } from '../services/global.service';
import { sklInn } from '../animasjon/animasjoner.animation';
import { ErrorHandteringService } from '../services/error-handtering.service';

@Component({
  selector: 'meny-mobil',
  templateUrl: './meny-mobil.component.html',
  styleUrls: ['./meny-mobil.component.scss'],
  animations: [
    sklInn
  ]
})
export class MenyMobilComponent extends MenyComponent implements OnInit {
  visMobilMeny: boolean;
  @ViewChild('menyWrapper', { static: true }) mWrapper: ElementRef;
  hindreOpningAvMeny: boolean;

  constructor(
    public db: AngularFirestore,
    _global: GlobalService,
    errorHandtering: ErrorHandteringService
  ) {
    super(db, _global, errorHandtering);
  }

  ngOnInit() {
    this.installer_kat_beh(false);
    this.slettUbrukteKat();

    let touch_stotta = new Event('touchstart') ? true : false;
    // For opning av menyen med touch-swipe
    if (touch_stotta) {
      // Side-DIV
      const el: HTMLElement = document.querySelector('.mobil-meny-touch-side');
      let tStart_x: number;
      let tSlutt_x: number;

      el.addEventListener('touchstart', (e) => {
        if (e.touches.length > 0) { tStart_x = e.touches[0].clientX; }
      });
      el.addEventListener('touchend', () => {
        setTimeout(() => {
          this.hindreOpningAvMeny = false;
        }, 100);
      });
      el.addEventListener('touchmove', (e) => {
        if (tStart_x && e.touches.length > 0) {
          tSlutt_x = e.touches[0].clientX;

          // Grense for å dra inn menyen
          const g = 40;
          if (tSlutt_x - tStart_x > g) {
            this.opneMobilMeny();

            this.hindreOpningAvMeny = true;
          }
        }
      });
    }
    // For lukking av menyen med touch-swipe
    if (touch_stotta) {
      // Wrapper for menyen
      const el: HTMLElement = this.mWrapper.nativeElement;
      let tStart_x: number;
      let tSlutt_x: number;

      el.addEventListener('touchstart', (e) => {
        if (e.touches.length > 0) { tStart_x = e.touches[0].clientX; }
      });
      el.addEventListener('touchmove', (e) => {
        if (tStart_x && e.touches.length > 0) {
          tSlutt_x = e.touches[0].clientX;

          // Grense for å dra inn menyen
          const g = 60;
          if (tStart_x - tSlutt_x > g) {
            this.opneMobilMeny();

            this.hindreOpningAvMeny = true;
            setTimeout(() => {
              this.hindreOpningAvMeny = false;
            }, 300);
          }
        }
      });
    }
  }

  opneMobilMeny(berre_lukk?: boolean) {
    if (!this.hindreOpningAvMeny) {
      this.slettUbrukteKat();

      if (this.menyErKlar) {
        if (berre_lukk === true) {
          this.visMobilMeny = false;
        } else {
          this.visMobilMeny = !this.visMobilMeny;
        }
        if (this.visMobilMeny) {
          document.querySelector('html').style.overflow = 'hidden';
        } else {
          document.querySelector('html').style.overflow = '';
        }
      }
    }
  }

  opneLukkeMenyElement(div: HTMLElement) {
    let beh_felt = div.nextElementSibling;

    if (!beh_felt.classList.contains('beh_felt_vekk')) { beh_felt.classList.add('beh_felt_vekk'); } else { beh_felt.classList.remove('beh_felt_vekk'); }
  }

}
