import { Component, OnInit } from '@angular/core';
import { loggUt } from '../animasjon/animasjoner.animation';
import { LoggUtComponent } from '../logg-ut/logg-ut.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import { GlobalService } from '../services/global.service';
import { ScrollTopComponent } from '../scroll-top/scroll-top.component';

@Component({
  selector: 'snarveg-admin',
  templateUrl: './snarveg-admin.component.html',
  styleUrls: ['./snarveg-admin.component.scss'],
  animations: [
    loggUt
  ]
})
export class SnarvegAdminComponent extends LoggUtComponent implements OnInit {

  constructor(
    afauth: AngularFireAuth,
    varsel: MatSnackBar,
    public _global: GlobalService,
    public _scroll: ScrollTopComponent
  ) { super(afauth, varsel); }

  ngOnInit() {
    // Må ha denne for å vise (e.g. på heimeside)?
    setTimeout(() => { }, 1000);

    this.afauth.onAuthStateChanged(d => {
      if (d) {
        this.afauth.user.subscribe(d2 => {
          if (d2 && this._global.er_administrator(d2.email)) {
            this.visLoggUt = true;
          } else {
            this.visLoggUt = false;
          }
        });
      } else {
        this.visLoggUt = false;
      }

    });
  }

  scrollTop() {
    this._scroll.scrollTop();
  }

}
