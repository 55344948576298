import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollTopComponent } from '../scroll-top/scroll-top.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandteringService } from '../services/error-handtering.service';

export class Brukerkontroll extends ErrorHandteringService {

  constructor(
    public ruter: Router,
    public _ngZone: NgZone,
    varsel: MatSnackBar,
    public scroll: ScrollTopComponent
  ) { super(varsel); }

  hentIdToken(data) {
    return new Promise(resolve => {
      data.user.getIdToken(true)
        .then(token => {
          resolve(token);
        })
        .catch(error => {
          resolve(null);

          this.visError(error, false, true);
        });
    });
  }

  // Må bruker NgZone av og til... ?
  _ngZoneFuncNavigering(url: string) {
    this._ngZone.run(() => {
      this.ruter.navigateByUrl(url);
      if (url === '' || url === 'admin') { this.scroll.scrollTop(); }
    });
  }
}
