import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  // DB
  // Kolleksjon
  public db_behandling_behandlinger = 'behandling_behandlinger';
  public db_behandling_kategorier = 'behandling_kategorier';
  // Felt for eit dokument
  public db_behandling_behandlinger_link_adresse = 'link_adresse';
  public db_behandling_behandlinger_aktiv = 'aktiv';

  public antalKategorier = 9; // Denne brukes for å berekne om menyen er ferdig lasta inn

  constructor() { }

  er_administrator(epost: string) {
    // Er berre for UI... Er sikkerheitssjekk for dette på
    // server også for redigering/tilegging av data
    const klarerte: string[] = ['riaskinbodycare@gmail.com', 'rassyrelling@gmail.com'];
    const index = klarerte.indexOf(epost);

    // OK
    if (index > -1) {
      return true;
    } else {
      // Ikkje OK
      return false;
    }
  }
}
