import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AktiveBehandlingerService } from '../../services/aktive-behandlinger.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CkeditorService } from '../services/ckeditor.service';
import { AsyncValidatorBehandling } from '../validators/async-validator.validator';
import { GlobalService } from '../../services/global.service';
import { ErrorHandteringService } from 'src/app/services/error-handtering.service';

@Component({
  selector: 'endre-behandling',
  templateUrl: './endre-behandling.component.html',
  styleUrls: ['./endre-behandling.component.scss']
})
export class EndreBehandlingComponent extends AktiveBehandlingerService implements OnInit, OnChanges {
  // For endring av ein teneste
  endreBehandling: {
    tittel: string,
    link_adresse: string,
    sok_stikkord: string,
    kategori: string,
    varigheit: string,
    pris: { nok: number },
    rekke: number,
    aktiv: boolean,
    beskrivelse: string
  }; // Data frå valt behandling
  @Input() endreVeksle: boolean;
  @ViewChild('e0', { static: true }) behandlingVelger: MatSelect;

  form: FormGroup;

  constructor(
    public db: AngularFirestore,
    fb: FormBuilder,
    public varsel: MatSnackBar,
    public ckeditor: CkeditorService,
    _global: GlobalService,
    errorHandtering: ErrorHandteringService
  ) {
    super(db, _global, errorHandtering);

    this.form = fb.group({
      tittel: fb.control('', [
        Validators.required,
        Validators.minLength(3)
      ], [
        AsyncValidatorBehandling.unikTittel(db, _global)
      ]),
      _link: fb.control('', [
        Validators.required,
        Validators.minLength(3)
      ], [
        AsyncValidatorBehandling.unikLink(db, _global)
      ]),
      stikkord: fb.control('', []),
      kategori: fb.control('', [
        Validators.required
      ]),
      varigheit: fb.control('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      pris: fb.control('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      rekke: fb.control('', [
        Validators.minLength(1),
        Validators.min(1)
      ]),
      aktiv: fb.control('', [
        Validators.required
      ]),
      beskrivelse: fb.control('', []),
      dok_id: ''
    });
  }

  ngOnInit() {
    this.installer_kat_beh(true);

    this.installer_kat();
    this.installer_beh();
  }

  ngOnChanges(forandinger: SimpleChanges) {
    // Om data skal bli reset for kategorifelt
    if (forandinger.endreVeksle.currentValue) {
      this.endreBehandling = null;

      // Reset selector felt
      if (this.behandlingVelger && this.behandlingVelger !== undefined) {
        this.behandlingVelger.writeValue('');
      }
    }
  }

  // Velg ein behandling å endre på
  velgBehandling(e: MatSelect) {
    if (e.value === '' || !e.value) {
      this.endreBehandling = null;

      return;
    }

    const funnet_e = this.behandlinger.find(d => {
      return d.id === e.value;
    });

    if (funnet_e) {
      this.endreBehandling = funnet_e.data;

      const d = funnet_e.data;
      const id = funnet_e.id;
      AsyncValidatorBehandling
        .konverterTilUformatertUppercaseLowercase(d.tittel)
        .then(ny_tittel => {
          this.form.setValue({
            tittel: ny_tittel,
            _link: d.link_adresse,
            stikkord: d.sok_stikkord,
            kategori: d.kategori,
            varigheit: d.varigheit,
            pris: d.pris.nok,
            rekke: d.rekke,
            aktiv: d.aktiv,
            beskrivelse: d.beskrivelse,
            dok_id: id
          });
        });

      // Oppdater midlertidig i DB for kva behandling som no skal endres på
      // sånn validator kan få vite det
      this.db
        .doc(`${this._global.db_behandling_behandlinger}/${id}`)
        .update({
          endres_paa_no: true
        });
    }
  }

  // For henting av formdata
  endreFormHentCN(cn) {
    return this.form.get(cn);
  }

  // Når formdata blir registrert (submit)
  _endreBehandling() {
    if (this.form.invalid) {
      return this.varsel.open('Ugyldig form!', '', {
        duration: 2000
      });
    }

    const data = this.form.value;
    this.varsel.open('Endringer lagres...', '', {
      duration: 10000
    });

    // Send til Firebase
    AsyncValidatorBehandling
      .konverterTilUppercaseLowercase(data.tittel)
      .then(verdi_tittel => {
        let rekke = data.rekke;
        if (!rekke || rekke < 1) { rekke = null; }

        this.db.doc(`${this._global.db_behandling_behandlinger}/${data.dok_id}`)
          .update({
            tittel: verdi_tittel,
            link_adresse: data._link,
            sok_stikkord: data.stikkord,
            kategori: data.kategori,
            varigheit: data.varigheit,
            pris: { nok: data.pris },
            rekke,
            aktiv: data.aktiv,
            beskrivelse: data.beskrivelse
          })
          .then(() => {
            this.varsel.open('Endringer lagret!', '', {
              duration: 2000
            });
          })
          .catch(error => {
            this.errorHandtering.visError(error, true, false);
          });
      });
  }

}
