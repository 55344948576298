import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { loggUt } from '../animasjon/animasjoner.animation';

@Component({
  selector: 'logg-ut',
  templateUrl: './logg-ut.component.html',
  styleUrls: ['./logg-ut.component.scss'],
  animations: [
    loggUt
  ]
})
export class LoggUtComponent implements OnInit {
  visLoggUt: boolean;

  constructor(
    public afauth: AngularFireAuth,
    public varsel: MatSnackBar
  ) { }

  ngOnInit(): void {
    // Må ha denne for å vise (e.g. på heimeside)?
    setTimeout(() => { }, 1000);

    this.afauth.onAuthStateChanged(d => {
      if (!d) {
        this.visLoggUt = false;
      } else {
        this.visLoggUt = true;
      }

    });
  }

  loggUt() {
    this.afauth.signOut();

    this.varsel.open('Du blei logga ut', '', {
      duration: 2000
    });
  }
}
