import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from './../../environments/environment';

import { AktiveBehandlingerService } from './../services/aktive-behandlinger.service';

import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CKEditorModule } from 'ng2-ckeditor';
import { EndreBehandlingComponent } from './endre-behandling/endre-behandling.component';
import { LeggtilBehandlingComponent } from './leggtil-behandling/leggtil-behandling.component';
import { SlettBehandlingComponent } from './slett-behandling/slett-behandling.component';
import { CkeditorService } from './services/ckeditor.service';
import { AdminComponent } from './admin.component';

@NgModule({
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatGridListModule,
    CKEditorModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  declarations: [
    EndreBehandlingComponent,
    LeggtilBehandlingComponent,
    SlettBehandlingComponent,
    AdminComponent
  ],
  providers: [
    AktiveBehandlingerService,
    CkeditorService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    AdminComponent
  ]
})
export class AdminModule { }
