import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { InstagramFeedPost } from '../type-vakt';
import { InstagramFeedToService } from '../services/annet/instagram-feed-to.service';

@Component({
  selector: 'app-stort-bilete',
  templateUrl: './stort-bilete.component.html',
  styleUrls: ['./stort-bilete.component.scss']
})
export class StortBileteComponent implements OnInit, OnDestroy, AfterContentInit {
  instagramFeed: InstagramFeedPost[];
  instagramFeedID: string;
  instagramFeedPost: InstagramFeedPost;

  abonnement0: Subscription;
  abonnement1: Subscription;

  // Lyttarar
  lyttarar: {
    type: string,
    funksjon: EventListener
  }[] = [];

  constructor(
    public DOMSanitizer: DomSanitizer,
    public instagramFeedService: InstagramFeedToService,
    private ruter: Router,
    private rute: ActivatedRoute
  ) {
    this.abonnement0 = this.ruter.events.subscribe(async data => {
      if (data instanceof NavigationEnd) {
        this.instagramFeedID = this.rute.snapshot.paramMap.get('id') ? this.rute.snapshot.paramMap.get('id') : null;
        this.abonnement1 = this.instagramFeedService.currInstagramPosterEndretData.subscribe(d => {
          this.instagramFeed = d;

          const post = this.instagramFeed ? this.instagramFeed.find((postData) => {
            return postData.id === this.instagramFeedID;
          }) : null;
          if (post) {
            // OK
            this.instagramFeedPost = post;
          } else {
            // Avbryt; lukk
            this.lukk();
          }

          if (this.abonnement1) { this.abonnement1.unsubscribe(); }
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.abonnement0) { this.abonnement0.unsubscribe(); }
    if (this.abonnement1) { this.abonnement1.unsubscribe(); }

    // Slett lyttarar
    this.lyttarar.forEach(v => {
      document.removeEventListener(v.type, v.funksjon);
    });
  }
  ngOnInit() {
  }
  ngAfterContentInit() {
    const lukk = (evt) => {
      if (evt.key === 'Escape') {
        this.lukk();
      }
    };

    // For å lukke vindaug
    document.addEventListener('keydown', lukk);
    this.lyttarar.push({
      type: 'keydown',
      funksjon: lukk
    });
  }

  lukk() {
    this.ruter.navigate(['', { outlets: { pop: null } }]);
  }

}
