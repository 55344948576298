import { Component, OnInit } from '@angular/core';
import { AktiveBehandlingerService } from '../services/aktive-behandlinger.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { GlobalService } from '../services/global.service';
import { sklInn2, menyFelt } from '../animasjon/animasjoner.animation';
import { ErrorHandteringService } from '../services/error-handtering.service';

@Component({
  selector: 'meny',
  templateUrl: './meny.component.html',
  styleUrls: ['./meny.component.scss'],
  animations: [
    sklInn2,
    menyFelt
  ]
})
export class MenyComponent extends AktiveBehandlingerService implements OnInit {
  visTenester = false;
  menyErKlar = false; // Brukes for å hindre opning av meny til den er klar
  intervall;
  indreVidde: number = window.innerWidth;

  constructor(
    db: AngularFirestore,
    public _global: GlobalService,
    errorHandtering: ErrorHandteringService
  ) {
    super(db, _global, errorHandtering);
  }

  ngOnInit() {
    this.installer_kat_beh(false);
    this.slettUbrukteKat();
  }

  slettUbrukteKat() {
    this.intervall = setInterval(() => {
      if (this.meny.length === this._global.antalKategorier) {
        clearInterval(this.intervall);

        let ny_meny: any[] = [];

        this.meny.forEach((kategori, i_kategori) => {
          if (kategori.behandlinger.length > 0) {
            ny_meny.push(this.meny[i_kategori]);
          }

          // Ferdig med menyen
          if ((i_kategori + 1) === this.meny.length) {
            this.meny = ny_meny;
            this.menyErKlar = true;
          }
        });
      }
    }, 50);
  }

  opneMeny(berre_lukk?: boolean) {
    if (this.meny) {
      // Slett ein kategori visst det er ingen behandling(er) i den
      this.slettUbrukteKat();

      setTimeout(() => {
        if (berre_lukk === true) {
          this.visTenester = false;
        } else {
          this.visTenester = !this.visTenester;
        }
      }, 10);
    } else {
      // Varlse om at meny ikkje har blitt lasta inn enno...
    }
  }

}
