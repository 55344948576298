import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent implements OnInit {
  mus_inni = false;
  touch_stotta: boolean = new Event('touchstart') ? true : false;
  opacity = '0.38';
  _right = '-10px';

  constructor() { }

  ngOnInit() {

  }

  inn(elm: HTMLElement) {
    if (!this.touch_stotta || window.innerWidth > 768) {
      elm.style.opacity = '1';
      elm.style.right = '0';

      this.mus_inni = true;
    }
  }
  ut(elm: HTMLElement) {
    if (!this.touch_stotta || window.innerWidth > 768) {
      elm.style.opacity = this.opacity;
      elm.style.right = this._right;

      this.mus_inni = false;
    }
  }

  scrollTop(elm?: HTMLElement) {
    window.scrollTo(0, 0);

    if (elm) {
      elm.style.opacity = '1';
      elm.style.right = '0';

      if (!this.mus_inni) {
        setTimeout(() => {
          elm.style.opacity = this.opacity;
          elm.style.right = this._right;
        }, 350);
      }
    }
  }

}
