export const errorKoder = [
    {
        kode: 'ukjent',
        meld: 'Ein feil... Prøv igjen'
    },
    {
        kode: 'auth/wrong-password',
        meld: 'Feil e-post eller brukarnamn'
    }
]
