import { Component, OnInit } from '@angular/core';
import { fade2 } from '../animasjon/animasjoner.animation';

@Component({
  selector: 'app-om',
  templateUrl: './om.component.html',
  styleUrls: ['./om.component.scss'],
  animations: [
    fade2
  ]

})
export class OmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
