import { Component, OnInit, NgZone } from '@angular/core';
import { Brukerkontroll } from '../bruker-kontroll/bruker-kontroll';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScrollTopComponent } from '../scroll-top/scroll-top.component';
import { GlobalService } from '../services/global.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { sklInnToppNed2 } from '../animasjon/animasjoner.animation';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    sklInnToppNed2
  ]
})
export class AdminComponent extends Brukerkontroll implements OnInit {
  brukarErAdmin: boolean;
  endreVeksle: boolean;

  constructor(
    public afauth: AngularFireAuth,
    ruter: Router,
    _ngZone: NgZone,
    varsel: MatSnackBar,
    scroll: ScrollTopComponent,
    public _global: GlobalService,
    public db: AngularFirestore
  ) { super(ruter, _ngZone, varsel, scroll); }

  veksle(id?) {
    // Lukk alle andre felt enn seg sjølv
    if (id) {
      this.endreVeksle = true;
    } else {
      this.endreVeksle = false;

      // Reset verdi i DB
      this.db.collection(this._global.db_behandling_behandlinger,
        ref => ref
          // Skal berre vere ein behandling som har 'endres_paa_no' satt til true
          .where('endres_paa_no', '==', true))
        .get()
        .toPromise()
        .then(d => {
          if (!d.empty) {
            this.db.doc(`${this._global.db_behandling_behandlinger}/${d.docs[0].id}`)
              .update({
                endres_paa_no: false
              });
          }
        });
    }
  }

  ngOnInit() {
    // Må ha denne for å få vekke laster-inn hjul?
    setTimeout(() => { }, 1000);

    this.afauth.onAuthStateChanged(d => {
      if (!d) {
        this._ngZoneFuncNavigering('');
      } else
        if (this._global.er_administrator(d.email)) {
          this.brukarErAdmin = true;
        } else {
          this._ngZoneFuncNavigering('');
        }
    });
  }

}
