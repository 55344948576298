import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { Brukerkontroll } from '../bruker-kontroll/bruker-kontroll';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScrollTopComponent } from '../scroll-top/scroll-top.component';
import { GlobalService } from '../services/global.service';
import { fade2 } from '../animasjon/animasjoner.animation';

@Component({
  selector: 'app-teneste',
  templateUrl: './teneste.component.html',
  styleUrls: ['./teneste.component.scss'],
  animations: [
    fade2
  ]
})
export class TenesteComponent extends Brukerkontroll implements OnInit, OnDestroy {
  _data: {
    tittel: string,
    link_adresse: string,
    sok_stikkord: string,
    kategori: string,
    varigheit: string,
    pris: { nok: number },
    rekke: number,
    aktiv: boolean,
    beskrivelse: string
  };
  timeout: any;
  animasjonIntervall: any;
  _data_kategorier: {
    kategori: string,
    tittel: string
  }[];

  sub0: Subscription;
  sub1: Subscription;
  sub2: Subscription;

  lastetInnEinGong = false;

  constructor(
    private db: AngularFirestore,
    private _ruter: ActivatedRoute,
    ruter: Router,
    _ngZone: NgZone,
    varsel: MatSnackBar,
    scroll: ScrollTopComponent,
    public _global: GlobalService
  ) {
    super(ruter, _ngZone, varsel, scroll);
  }

  ngOnInit() {
    // Hent ut kategorier
    this.sub0 = this.db
      .collection<{ tittel: string, kategori: string }>(this._global.db_behandling_kategorier)
      .valueChanges()
      .subscribe(data => this._data_kategorier = data);

    // Hent behandlings-data frå Firebase og vis
    this.sub2 = this._ruter.paramMap.subscribe(param => {
      if (this.sub1) { this.sub1.unsubscribe(); }

      const behandling = param.get('teneste');

      this.sub1 = this.db
        .collection<TenesteComponent['_data']>(this._global.db_behandling_behandlinger,
          ref => ref
            .where(this._global.db_behandling_behandlinger_link_adresse, '==', behandling)
            .where(this._global.db_behandling_behandlinger_aktiv, '==', true))
        .valueChanges()
        .subscribe(data => {
          this._data = data[0]; // Skal berre vere eit resultat... (link_adresse skal vere unik)

          if (!this._data) {
            this._ngZoneFuncNavigering('');
          } else {
            let _e: HTMLElement | null = null;

            while (!_e && !this.lastetInnEinGong) {
              _e = document.querySelector('#behandling');

              if (_e) {
                this.lastetInnEinGong = true;
                this.scrollAnimasjon(_e);
              }
            }
          }
        });
    });
  }

  scrollAnimasjon(elm: HTMLElement) {
    const slutt_pos: number = elm.offsetTop - 193;

    window.scrollTo({
      top: slutt_pos,
      behavior: 'smooth'
    });

    setTimeout(() => {
      this.lastetInnEinGong = false;
    }, 500);

    /* let i: number = window.scrollY;
    // Under element
    if (i > slutt_pos) {
      this.animasjonIntervall = setInterval(() => {
        i -= 14;
        window.scrollTo(0, i);

        if (i <= slutt_pos) { clearInterval(this.animasjonIntervall); }
      }, 4);
    } else {
      this.animasjonIntervall = setInterval(() => {
        i += 14;
        window.scrollTo(0, i);

        if (slutt_pos - i <= 0) { clearInterval(this.animasjonIntervall); }
      }, 4);
  } */
  }

  ngOnDestroy() {
    this.sub0.unsubscribe();
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();
  }

}
