import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InstagramFeedPost } from '../../type-vakt';

@Component({
  selector: 'app-instagram-feed-to',
  templateUrl: './instagram-feed-to.component.html',
  styleUrls: ['./instagram-feed-to.component.scss']
})
export class InstagramFeedToComponent implements OnInit {
  @Input() instagramDataFeed: InstagramFeedPost[];
  @ViewChild('feed', { static: true }) feed: ElementRef<HTMLElement>;

  constructor(public DOMSanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // Forrige/neste
    (() => {
      const instagramWrapper = this.feed.nativeElement.parentElement;
      const instagramElement = this.feed.nativeElement;

      let startKlikkX;
      let startKlikkY;
      const klikkNed = (evt: Event, STARTKLIKK_X, STARTKLIKK_Y) => {
        startKlikkX = STARTKLIKK_X;
        startKlikkY = STARTKLIKK_Y;
      };
      const klikkBeveg = (evt: Event, KLIKK_X, KLIKK_Y) => {
        // Kanskje preventDefault
        KLIKK_X = KLIKK_X < 0 ? (KLIKK_X * -1) : KLIKK_X;
        KLIKK_Y = KLIKK_Y < 0 ? (KLIKK_Y * -1) : KLIKK_Y;
        if ((KLIKK_Y - startKlikkY) > 20 && (KLIKK_X - startKlikkX) > 20) {
          // Ikkje i ein cirka rett linje, annta att brukar vil bytte tips...
          evt.preventDefault();
        }
      };
      const klikkOpp = (evt: Event, SLUTTKLIKK_X, SLUTTKLIKK_Y) => {
        SLUTTKLIKK_Y = SLUTTKLIKK_Y < 0 ? (SLUTTKLIKK_Y * -1) : SLUTTKLIKK_Y;
        if ((Math.max(SLUTTKLIKK_Y, startKlikkY) - (Math.min(SLUTTKLIKK_Y, startKlikkY))) > 50) {
          // Ikkje i ein cirka rett linje, annta att brukar vil bytte bilete...
          return;
        }

        if ((startKlikkX - SLUTTKLIKK_X) <= -20) {
          // Forrige
          this.scroll('eldre', instagramElement);
        } else if ((startKlikkX - SLUTTKLIKK_X) >= 20) {
          // Neste
          this.scroll('nyare', instagramElement);
        }
      };

      // For kvar event
      const touchNed = (evt: TouchEvent) => klikkNed(evt, evt.changedTouches[0].clientX, evt.changedTouches[0].clientY);
      const touchBeveg = (evt: TouchEvent) => klikkBeveg(evt, evt.changedTouches[0].clientX, evt.changedTouches[0].clientY);
      const touchOpp = (evt: TouchEvent) => klikkOpp(evt, evt.changedTouches[0].clientX, evt.changedTouches[0].clientY);
      const musNed = (evt: MouseEvent) => klikkNed(evt, evt.clientX, evt.clientY);
      const musOpp = (evt: MouseEvent) => klikkOpp(evt, evt.clientX, evt.clientY);

      // Legg til sånn du kan swipe
      try {
        if (
          new TouchEvent('touchend') &&
          new TouchEvent('touchmove')
        ) {
          // Mobil etc.
          instagramWrapper.addEventListener('touchstart', touchNed, { passive: true });
          // ELMT.addEventListener('touchmove', touchBeveg);
          instagramWrapper.addEventListener('touchend', touchOpp);
        }
      } catch (error) {
        const melding: string = error.message;
        if (melding.toLowerCase().indexOf('touchevent') === -1) {
          console.error(error);
        }
      }

      // PC etc.
      instagramWrapper.addEventListener('mousedown', musNed);
      instagramWrapper.addEventListener('mouseup', musOpp);
    })();
  }

  scroll(kvaVeg: ('eldre' | 'nyare'), innhald: HTMLElement) {
    // Avbryt
    if (!this.instagramDataFeed) { return; }

    let currPosisjon: string | number = innhald.style.transform;
    if (typeof currPosisjon === 'string') {
      let s = currPosisjon.slice('translateX('.length);
      const i = s.indexOf('px)');
      s = s.slice(0, i);

      currPosisjon = Number(s);
    }
    const scrollMengde = (/* this.instagramDataFeed[0].images.thumbnail.width */ 160 + 10 * 2);
    let nyPos = 0;

    if (kvaVeg === 'eldre') {
      nyPos = currPosisjon + scrollMengde;

      // Avbryt
      if (currPosisjon >= 0) { return; }
    } else if (kvaVeg === 'nyare') {
      nyPos = currPosisjon - scrollMengde;

      // Avbryt
      if (nyPos === (this.instagramDataFeed.length * (scrollMengde)) * -1) { return; }
    }

    // OK
    innhald.style.transform = `translateX(${nyPos}px)`;
  }

}
