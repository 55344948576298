import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fot',
  templateUrl: './fot.component.html',
  styleUrls: ['./fot.component.scss']
})
export class FotComponent implements OnInit {
  aarsTidNo: number;

  constructor() { }

  ngOnInit() {
    this.aarsTidNo = new Date().getFullYear();
  }

}
