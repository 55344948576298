import { Component, OnInit } from '@angular/core';
import { sklInnToppNed2, fade } from '../animasjon/animasjoner.animation';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss'],
  animations: [
    sklInnToppNed2,
    fade
  ]
})
export class KontaktComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
