import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AngularFirestore, DocumentChangeAction, DocumentReference } from '@angular/fire/firestore';
import { GlobalService } from './global.service';
import { ErrorHandteringService } from './error-handtering.service';

@Injectable({
  providedIn: 'root'
})
export class AktiveBehandlingerService implements OnDestroy {
  private db_behandling_kategorier: Observable<any>;
  behandling_kategoriar: any[];

  private db_behandlinger: Observable<any>;
  behandlinger: any[];

  meny: {
    kategori: string,
    tittel: string,
    behandlinger: {
      id: string,
      tittel: string,
      link_adresse: string,
      rekke: number,
      prisNOK: string
    }[]
  }[] = [];
  meny_behandlingar: any[] = [];
  meny_kategoriar: {}[] = [];

  sub0: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;

  constructor(
    public db: AngularFirestore,
    public _global: GlobalService,
    public errorHandtering: ErrorHandteringService
  ) {
    this.db_behandling_kategorier = db.collection(this._global.db_behandling_kategorier).snapshotChanges();
    this.db_behandlinger = db.collection(this._global.db_behandling_behandlinger).snapshotChanges();
  }

  ngOnDestroy(): void {
    if (this.sub0 && this.sub0 !== undefined) { this.sub0.unsubscribe(); }
    if (this.sub1 && this.sub1 !== undefined) { this.sub1.unsubscribe(); }
    if (this.sub2 && this.sub2 !== undefined) { this.sub2.unsubscribe(); }
    if (this.sub3 && this.sub3 !== undefined) { this.sub3.unsubscribe(); }
  }

  /*
    Returner kategori og behandlingar under dei kategoriane
  */
  installer_kat_beh(er_for_select: boolean) {
    // Behandlingskategorier
    this.sub0 = this.db_behandling_kategorier.subscribe(d => {
      const _d: any[] = d;
      const kat_a: any[] = [];

      _d.forEach(kat => {
        const _kat: DocumentChangeAction<any> = kat;

        kat_a.push({
          data: _kat.payload.doc.data(),
          id: _kat.payload.doc.id
        });
      });

      // Behandlinger
      this.sub1 = this.db_behandlinger.subscribe(behandlingsData => {
        this.behandling_kategoriar = kat_a;

        const _behandlingsData: any[] = behandlingsData;
        const beh_a: any[] = [];

        _behandlingsData.forEach(beh => {
          const _beh: DocumentChangeAction<any> = beh;

          beh_a.push({
            data: _beh.payload.doc.data(),
            id: _beh.payload.doc.id
          });
        });

        setTimeout(() => {
          this.behandlinger = beh_a;
          this.skrivMeny(er_for_select);
        }, 100);
      });

    });
  }
  /*
    Returner berre kategoriar
  */
  installer_kat() {
    // Behandlings-kategorier
    this.sub2 = this.db_behandling_kategorier.subscribe(d => {
      const _d: any[] = d;
      const kat_a: any[] = [];

      _d.forEach((kat, i) => {
        const _kat: DocumentChangeAction<any> = kat;

        kat_a.push({
          data: _kat.payload.doc.data(),
          id: _kat.payload.doc.id
        });

        if ((i + 1) === _d.length) {
          this.behandling_kategoriar = kat_a;
          this.skrivKategoriar();
        }
      });
    });
  }
  /*
    Returner berre behandlingar
  */
  installer_beh() {
    // Behandlinger
    this.sub3 = this.db_behandlinger.subscribe(d => {
      const _d: any[] = d;
      const beh_a: any[] = [];

      _d.forEach((beh, i) => {
        const _beh: DocumentChangeAction<any> = beh;

        beh_a.push({
          data: _beh.payload.doc.data(),
          id: _beh.payload.doc.id
        });

        if ((i + 1) === _d.length) {
          this.behandlinger = beh_a;
          this.skrivBehandlingar();
        }
      });
    });
  }

  /*
    Skriv 'menydata' med kategori og behandling
  */
  private skrivMeny(er_for_select: boolean) {
    // Tøm meny fyrst
    this.meny = [];
    const _meny: {
      kategori: string,
      tittel: string,
      behandlinger: {
        id: string,
        tittel: string,
        link_adresse: string,
        rekke: number,
        prisNOK: string
      }[]
    }[] = [];

    const kategorier: any[] = this.behandling_kategoriar;
    const behandlinger: any[] = this.behandlinger;

    kategorier.forEach((d_kat, i_kat) => {
      d_kat = d_kat.data;

      // Dytt ein kategori inn
      _meny.push({
        kategori: d_kat.kategori,
        tittel: d_kat.tittel,
        behandlinger: []
      });

      behandlinger.forEach((d_beh, i_beh) => {
        const _id = d_beh.id;
        d_beh = d_beh.data;

        // Legg berre til kategori om den har ein gyldig kategori (eksisterer)
        if (d_beh.kategori === d_kat.kategori && (d_beh.aktiv || er_for_select)) {
          // Dytt ein behandling inn
          _meny[i_kat].behandlinger.push({
            id: _id,
            tittel: d_beh.tittel,
            link_adresse: d_beh.link_adresse,
            rekke: d_beh.rekke,
            prisNOK: d_beh.pris.nok
          });
        }

        function velg_verdi(objekt: {
          rekke: number
          tittel: string
        }) {
          // Auto = null = sorter alfabetisk
          if (!objekt.rekke) {
            return String(objekt.tittel);
          } else { // Nummer = nummer = sorter etter nummer
            return Number(objekt.rekke);
          }
        }
        function finn_fyrste_bokstav(verdi: string | number) {
          // Finn fyrste teiknet i tittel som er ein bokstav om nødvendig.. e.g.: 'kollagen-hy..' = index: 1
          if (typeof verdi === 'string') {
            const i = verdi.search(/[a-z]|[æøåÆØÅ]/gi);

            // Det er ein ikkje-bokstav på plass 0
            // .. Klipp vekk det teiknet
            if (i > -1 && i > 0) {
              return String(verdi).slice(i);
            } else {
              return String(verdi);
            }
          } else { return Number(verdi); }
        }

        if ((i_beh + 1) === behandlinger.length) {
          // Sorter heile arrayen med behandlinger under kategorien etter det brukar vil
          const _behandlinger: any[] = _meny[i_kat].behandlinger;
          _behandlinger.sort((a: {
            rekke: number
            tittel: string
          },                  b: {
            rekke: number
            tittel: string
          }): any | void => {
            const _a = a;
            const _b = b;

            let __a: number | string;
            let __b: number | string;

            __a = velg_verdi(a);
            __b = velg_verdi(b);

            __a = finn_fyrste_bokstav(__a);
            __b = finn_fyrste_bokstav(__b);

            /* Logikk */
            // Eit nummer skal få ein høgare plass enn ein bokstav uansett...
            // Dette er ikkje standard i "localeCompare"...
            if (typeof a === 'number' && typeof b === 'number') {
              // Sorter etter nummer (mindre tal kjem fyrst)

              // Visst det er sama kategori-nummer, sorter alfabetisk (tittel)
              if (String(a).localeCompare(String(b)) === 0) {
                __a = String(_a.tittel);
                __b = String(_b.tittel);

                __a = finn_fyrste_bokstav(__a);
                __b = finn_fyrste_bokstav(__b);

                return String(__a).localeCompare(String(__b));
              } else {
                return String(__a).localeCompare(String(__b));
              }
            } else {
              // Sorter etter tittel (alfabetisk)(rekke === null)
              return String(__a).localeCompare(String(__b));
            }
          });

          // Ferdig
          if ((i_kat + 1) === kategorier.length) {
            this.meny = _meny;
          }
        }
      });
    });
  }

  /*
    Skriv 'menydata' med kategori
  */
  private skrivKategoriar() {
    // Tøm meny fyrst
    this.meny_kategoriar = [];

    const kategoriar: any[] = this.behandling_kategoriar;

    kategoriar.forEach(d => {
      // Dytt ein behandling inn
      this.meny_kategoriar.push({
        data: d.data,
        id: d.id
      });
    });
  }
  /*
    Skriv 'menydata' med behandling
  */
  private skrivBehandlingar() {
    // Tøm meny fyrst
    setTimeout(() => {
      this.meny_behandlingar = [];

      const behandlinger: any[] = this.behandlinger;

      behandlinger.forEach(d => {
        // Dytt ein behandling inn
        this.meny_behandlingar.push({
          data: d.data,
          id: d.id
        });
      });
    }, 600);
  }
}
