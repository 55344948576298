import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'signatur',
  templateUrl: './signatur.component.html',
  styleUrls: ['./signatur.component.scss']
})
export class SignaturComponent implements OnInit {
  @Input() dato: string;
  @Input() cssStil: Object;

  constructor() { }

  ngOnInit() {
    /* E.g.:
      [cssStil]="{
        marginLeft: '-10px',
        marginBottom: '-30px',
        width: '100% + (10px * 2)',
        farge: '#c3e5d7',
        fargeTekst: 'black'
      }
    */
    document.querySelector('#signatur').setAttribute('style', `
      margin-left: calc(${this.cssStil['marginLeft']});
      margin-bottom: calc(${this.cssStil['marginBottom']});
      width: calc(${this.cssStil['width']});
      color: ${this.cssStil['fargeTekst']}
    `);
    document.querySelector('#signatur-0').setAttribute('style', `
      border-color: ${this.cssStil['farge']};
    `);
    document.querySelector('#signatur-1').setAttribute('style', `
      background-color: ${this.cssStil['farge']};
    `);
    document.querySelector('#signatur-2').setAttribute('style', `
      color: dodgerblue;
    `);
  }

}
