import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormGroupDirective } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CkeditorService } from './../services/ckeditor.service';
import { AktiveBehandlingerService } from '../../services/aktive-behandlinger.service';
import { AsyncValidatorBehandling } from '../validators/async-validator.validator';
import { GlobalService } from '../../services/global.service';
import { CKEditorModule } from 'ng2-ckeditor';
import { ErrorHandteringService } from 'src/app/services/error-handtering.service';

@Component({
  selector: 'leggtil-behandling',
  templateUrl: './leggtil-behandling.component.html',
  styleUrls: ['./leggtil-behandling.component.scss']
})
export class LeggtilBehandlingComponent extends AktiveBehandlingerService implements OnInit {
  // For tillegging av behandling
  form: FormGroup;
  @ViewChild('ckeditor_editor', { static: true }) ckeditor_editor: CKEditorModule;

  constructor(
    public db: AngularFirestore,
    fb: FormBuilder,
    public varsel: MatSnackBar,
    public ckeditor: CkeditorService,
    public _global: GlobalService,
    errorHandtering: ErrorHandteringService
  ) {
    super(db, _global, errorHandtering);

    this.form = fb.group({
      tittel: fb.control('', [
        Validators.required,
        Validators.minLength(3)
      ], [
          AsyncValidatorBehandling.unikTittel(db, _global)
        ]),
      _link: fb.control('', [
        Validators.required,
        Validators.minLength(3)
      ], [
          AsyncValidatorBehandling.unikLink(db, _global)
        ]),
      stikkord: fb.control('', []),
      kategori: fb.control('', [
        Validators.required
      ]),
      varigheit: fb.control('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      pris: fb.control('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      rekke: fb.control('', [
        Validators.minLength(1),
        Validators.min(1)
      ]),
      aktiv: fb.control(true, [
        Validators.required
      ]),
      beskrivelse: fb.control('', [])
    });
  }

  ngOnInit() {
    this.installer_kat();
  }

  lagFormHentCN(cn) {
    return this.form.get(cn);
  }

  // Når formdata blir registrert (submit)
  _lagBehandling(fbg: FormGroupDirective) {
    if (this.form.invalid) {
      return this.varsel.open('Ugyldig form!', '', {
        duration: 2000
      });
    }

    const data = this.form.value;
    this.varsel.open('Legger til behandling...', '', {
      duration: 10000
    });

    // Send til Firebase
    AsyncValidatorBehandling
      .konverterTilUppercaseLowercase(data.tittel)
      .then(verdi_tittel => {
        let rekke = data.rekke;
        if (!rekke || rekke < 1) { rekke = null; }

        this.db.collection(this._global.db_behandling_behandlinger)
          .add({
            tittel: verdi_tittel,
            link_adresse: data._link,
            sok_stikkord: data.stikkord,
            kategori: data.kategori,
            varigheit: data.varigheit,
            pris: { nok: data.pris },
            rekke,
            aktiv: data.aktiv,
            beskrivelse: data.beskrivelse
          })
          .then(() => {
            // Slett form (reset)
            fbg.resetForm('');
            this.form.reset('');
            this.form.updateValueAndValidity();

            this.form.get('aktiv').reset(true);
            this.form.get('beskrivelse').setValue('');
            //
            this.varsel.open('Behandling lagt til!', '', {
              duration: 2000
            });
          })
          .catch(error => {
            this.errorHandtering.visError(error, true, false);
          });
      });
  }

}
