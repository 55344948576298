import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Brukerkontroll } from '../bruker-kontroll/bruker-kontroll';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScrollTopComponent } from '../scroll-top/scroll-top.component';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-logg-inn',
  templateUrl: './logg-inn.component.html',
  styleUrls: ['./logg-inn.component.scss']
})
export class LoggInnComponent extends Brukerkontroll implements OnInit {
  form: FormGroup;
  brukarLoggaInn = true;

  constructor(
    fb: FormBuilder,
    public afauth: AngularFireAuth,
    ruter: Router,
    _ngZone: NgZone,
    varsel: MatSnackBar,
    scroll: ScrollTopComponent,
    public _global: GlobalService
  ) {
    super(ruter, _ngZone, varsel, scroll);

    this.form = fb.group({
      epost: fb.control('', [
        Validators.required,
        Validators.email
      ]),
      passord: fb.control('', [
        Validators.required
      ])
    });
  }

  ngOnInit() {
    // Må ha denne for å få vekke laster-inn hjul?
    setTimeout(() => { }, 1000);

    // Send til heimesida om innlogga som brukar (ikkje der enno...) eller
    // send til Administratorpanel om logga inn som administrator
    this.afauth.onAuthStateChanged(d => {
      if (d) {
        if (this._global.er_administrator(d.email)) {
          // Admin.
          this._ngZoneFuncNavigering('admin');
        } else if (d.email !== '') {
          // Heim
          this._ngZoneFuncNavigering('');
 }
      } else {
        this.brukarLoggaInn = false;
      }
    });
  }

  logginn() {
    this.afauth.signInWithEmailAndPassword(
        this.form.get('epost').value,
        this.form.get('passord').value,
      )
      .then(() => {
        // Send videre til Administratorside
        this.ruter.navigateByUrl('admin');

        this.varsel.open('Du blei logga inn som Administrator!', '', {
          duration: 3500
        });
      })
      .catch(error => {
        this.visError(error, true, false);
      });
  }

}
