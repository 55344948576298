import { Injectable, OnInit } from '@angular/core';
import { errorKoder } from './errorkoder.error';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandteringService {

  constructor(public varsel: MatSnackBar) { }

  visError(error: { code: string } /* Error */, visTilBrukar: boolean, visIKonsoll: boolean) {
    // Vis til brukar
    if (visTilBrukar) {
      let i = 0;
      for (let _error of errorKoder) {
        // For Firebase error-kode
        if (error instanceof ErrorEvent && _error.kode === error.code) {
          // Vis til brukar
          this.varsel.open(_error.meld, '', {
            duration: 2000
          });

          break;
        }
        i++;

        // Alle koder er sjekka, men ingen treff
        // Vis den ukjente erroren
        if (i === Object.keys(errorKoder).length) {
          for (let _error of errorKoder) {

            //  Vis den ukjente erroren
            if (_error.kode === 'ukjent') {
              // Vis til brukar
              this.varsel.open(_error.meld, '', {
                duration: 4000
              });
            }
          }
        }
      }
    }
    // Vis til konsollen
    if (visIKonsoll) {
      console.error(error);
    }
  }
}
