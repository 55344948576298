import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'riaApp';

  tidut: any;
  mobilMeny = false;

  constructor(
    public ruter: Router
  ) { }

  ngOnInit() {
    this.ruter.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {
        // For alle (Scroll) om nede ved footer og skifter ruter-posisjon
        if (window.scrollY >= 1197) {
          clearTimeout(this.tidut);
          this.tidut = setTimeout(() => {
            window.scrollTo(0, 0);
          }, 5);
        }
      }
    });

    this.sjekkVidde();
    window.addEventListener('resize', () => {
      this.sjekkVidde();
    });
  }

  sjekkVidde() {
    if (window.innerWidth > 768) { this.mobilMeny = false; } else { this.mobilMeny = true; }
  }
}
