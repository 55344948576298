import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AktiveBehandlingerService } from '../../services/aktive-behandlinger.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { GlobalService } from '../../services/global.service';
import { ErrorHandteringService } from 'src/app/services/error-handtering.service';

export class AsyncValidatorBehandling extends AktiveBehandlingerService {

  constructor(
    public db: AngularFirestore,
    _global: GlobalService,
    errorHandtering: ErrorHandteringService
  ) { super(db, _global, errorHandtering); }

  // Konverter string for å tilpasse store eller liten bokstav (til tillegging av behandling)
  static konverterTilUppercaseLowercase(verdi: string): Promise<string> {
    let _verdi: string[] = verdi.split(' ');

    return new Promise(resolve => {
      for (let i = 0; i < _verdi.length; i++) {
        if (_verdi[i] && _verdi[i].split('')[0]) {
          if (
            _verdi[i].length > 0 &&
            _verdi[i].indexOf('{') > -1 &&
            _verdi[i].indexOf('}') > -1
          ) {
            // Skal ha liten bokstav uansett
            _verdi[i] = _verdi[i].slice(_verdi[i].indexOf('{') + 1, _verdi[i].indexOf('}'));
          } else {
            // Skal ha stor bokstav etter mellomrom (vanlig)
            if (_verdi[i].length > 0) {
              _verdi[i] =
                _verdi[i].split('')[0].toUpperCase() +
                _verdi[i].split('').slice(1).join('');
            } else {
              _verdi[i] =
                _verdi[i].split('')[0].toUpperCase();
            }
          }

          // Lagre
          if ((i + 1) === _verdi.length) {
            resolve(_verdi.join(' '));
          }
        } else {
          resolve(_verdi.join(' '));
        }
      }
    });
  }
  // Konverter baklengs til riktig string
  // Konverter string for å tilpasse store eller liten bokstav (til tillegging av behandling)
  static konverterTilUformatertUppercaseLowercase(verdi: string): Promise<string> {
    return new Promise(resolve => {
      let _verdi: string[] = verdi.split(' ');

      for (let i = 0; i < _verdi.length; i++) {
        if (_verdi[i] && _verdi[i].split('')[0]) {
          if (
            _verdi[i].length > 0 &&
            _verdi[i][0].search(/[a-zæøå]/) > -1
          ) {
            // Skal ha liten bokstav uansett
            // Finn fyrste teikn som ikkje er ein liten bokstav
            if (_verdi[i].search(/[^a-zæøå]/) > -1) {
              _verdi[i] = '{' + _verdi[i].slice(0, _verdi[i].search(/[^a-zæøå]/)) + '}';
            } else {
              _verdi[i] = '{' + _verdi[i].slice(0) + '}';
            }
          }

          // Lagre
          if ((i + 1) === _verdi.length) {
            resolve(_verdi.join(' '));
          }
        } else {
          resolve(_verdi.join(' '));
        }
      }
    });
  }

  // Hent ut og returner om unik eller ikkje
  static behandling(
    afs: AngularFirestore,
    _global: GlobalService,
    felt: string,
    returObject: Object
  ) {
    return (kontroll: AbstractControl): Promise<ValidationErrors | null> => {
      // Sett alle bokstaver mellom mellomrom til Uppercase om det er ein tittel
      return this
        .konverterTilUppercaseLowercase(kontroll.value)
        .then(ny_kontroll_verdi => {
          // Vanlig
          let kontroll_verdi = kontroll.value;
          if (felt === 'tittel') {
            // Berre uppercase
            kontroll_verdi = ny_kontroll_verdi;
          }
          setTimeout(() => { }, 100);

          return new Promise(resolve => {
            return afs.collection(_global.db_behandling_behandlinger,
              ref => ref
                .where(felt, '==', kontroll_verdi))
              .get()
              .toPromise()
              .then(d => {
                // Sjekk kva behandling som endres på, sånn ein ikkje viser error at
                // behandlings-tittel/link-adresse må vere unik; sidan det er eigar som endres på !
                // Dette er meint å brukast berre for ein endring av ein behandling; ikkje tillegging
                let docsData: any = d.docs[0].data();
                if (!d.empty && !docsData.endres_paa_no) {
                  resolve(returObject);
                } else {
                  resolve(null);
                }
              });
          });
        });
    };
  }

  static unikTittel(afs: AngularFirestore, _global: GlobalService) {
    return this.behandling(
      afs,
      _global,
      'tittel',
      { unikTittel: /* Namnet her må vere det same som funksjonen ! */ true });
  }
  static unikLink(afs: AngularFirestore, _global: GlobalService) {
    return this.behandling(
      afs,
      _global,
      'link_adresse',
      { unikLink: /* Namnet her må vere det same som funksjonen ! */ true });
  }
}
