import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MerkevareTekstService } from '../services/merkevare-tekst.service';

@Component({
  selector: 'overlapp-felt',
  templateUrl: './overlapp-felt.component.html',
  styleUrls: ['./overlapp-felt.component.scss'],
})
export class OverlappFeltComponent {
  @Input() tekstData: string;
  @Output() lukkFelt = new EventEmitter();

  constructor(private db: MerkevareTekstService) { }

  lukk() {
    this.lukkFelt.emit(true);
  }

  get hentData() {
    return this.db.tekst(this.tekstData);
  }
}
