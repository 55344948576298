import { trigger, transition, style, animate, query, stagger, group } from "@angular/animations";

// Brukes på kontainer som skal skli inn
export const sklInnToppNed = trigger('skli-tn', [
    transition('void => *', [
        style({
            transform: 'translateY(-10px)',
            opacity: .2
        }),
        animate(600)
    ])
])
export const sklInnToppNed2 = trigger('skli-tn', [
    transition('void => *', [
        style({
            transform: 'translateY(-10px)',
            opacity: .2
        }),
        animate(350)
    ])
])
// Brukes på kontainer som skal skli inn
export const sklInnHogreVenstre = trigger('skli-tn', [
    transition('void => *', [
        style({
            transform: 'translateY(-5px)',
            opacity: .2
        }),
        animate(400)
    ])
])
// Brukes på e.g. heimesida
export const stokker_om = trigger('stokker', [
    transition('void => *', [
        query('.om', [
            style({
                opacity: 0,
                transform: 'translateX(-10px)'
            }),
            stagger(200, [
                animate('300ms .2s', style('*'))
            ])
        ])
    ])
])
// Brukes i mobil-meny
export const sklInn = trigger('skli-inn', [
    transition('void => *', [
        style({
            transform: 'translateX(-50%)',
            opacity: 0.3
        }),
        animate('150ms ease-out')
    ]),
    transition('* => void', [
        style('*'),
        animate('150ms ease-in', style({
            transform: 'translateX(-100%)'
        }))
    ])
])
// Brukes i vanlig meny
export const sklInn2 = trigger('skli-inn', [
    transition('void => *', [
        style({
            transform: 'translateX(10%)',
            opacity: 0
        }),
        animate(450)
    ])
])
export const menyFelt = trigger('meny-felt', [
    transition('void => *', [
        style({
            top: '6em',
            opacity: 1
        }),
        animate('250ms ease-out')
    ]),
    transition('* => void', [
        style('*'),
        animate('130ms ease-in', style({
            top: '6em',
            opacity: 0
        }))
    ])
])
// Logg ut
export const loggUt = trigger('logg-ut', [
    transition('void => *', [
        style({
            transform: 'translateX(10%)',
            opacity: 0
        }),
        animate(450)
    ]),
    transition('* => void', [
        style('*'),
        animate(75, style({
            transform: 'translateY(-100%)',
        }))
    ])
])
// Timebestilling
export const fade = trigger('fade', [
    transition('void => *', [
        style({
            opacity: .2
        }),
        animate(1000)
    ])
])
// Om
export const fade2 = trigger('fade', [
    transition(':enter', [
        group([
            style({
                opacity: .2
            }),
            animate(1000),

            query('.om', [
                style({
                    transform: 'translateY(-10px)',
                    opacity: .2
                }),
                animate(350)
            ], { optional: true }),
            query('.geometri', [
                style({
                    backgroundSize: '80%'
                }),
                animate(200)
            ], { optional: true })
        ])
    ])
])

// Overlapp felt
export const overlapp = trigger('overlapp', [
    transition('void => *', [
        style({ position: 'fixed', zIndex: 199, opacity: 0.3 }),
        animate(250, style({
            zIndex: 199,
            opacity: 1
        }))
    ]),
    transition('* => void', [
        style({ position: 'fixed', zIndex: 199, opacity: 1 }),
        animate(250, style({
            zIndex: 199,
            opacity: 0
        }))
    ])
])
