import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MerkevareTekstService {
  private merkevareTekst: {
    elixir: string,
    sothys: string,
    glo: string,
    cnd: string,
    badnor: string
  } = {
      elixir: `
      <i><u>Elixir Cosmeceuticals</u></i>
      <br>
      <br>
      &ldquo;Elixir Cosmeceuticals er Cosmedicas egen serie cosmeceutical-produkter.
      Serien er satt sammen og utviklet av norske hudleger.
      Mange av produktene er egenformulerte, mens andre er håndplukket fra utvalgte laboratorier og produsenter.
      <br>
      For å alltid oppnå høyeste kvalitet på alle produkter, jobber vi med ulike produsenter,
      som alle har meget høy kompetanse innen aktive og dokumenterte virkestoffer. Det er spesielt viktig
      for oss at virkestoffene vi bruker i våre produkter har virkning som er dokumentert gjennom forskning.
      <br>
      <br>
      Dette er våre kunders garanti for at <strong>Elixir</strong> leverer det beste innen moderne hudpleie.&rdquo;
    `,
      sothys: `
      <i><u>Sothys</u></i>
      <br>
      <br>
      &ldquo;Velkommen inn i Sothys verden.
      <br>
      <br>
      Sothys, en verden av svært raffinerte følelser og sensualitet, et legendarisk navn
      som representerer kvalitet og prestisje i skjønnhetssalonger og spa over hele verden.
      Den samme lidenskap for perfeksjon og den samme søken etter absolutt skjønnhet har åpenbart
      seg for kvinner og menn gjennom velvære og effektivitet i over 60 år. Et svært spesialisert
      merkevare. Sothys har alltid stått frem med sin ekstraordinære
      satsing på forskning og innovasjon. <strong>Sothys</strong> hevder sin plass
      som en avantgarde skjønnhetsekspert.
      <br>
      Det er skaperen av Digi-Esthétique®, en eksklusiv massasje metode, som ved hjelp
      av terapeutens ekspertise - forvandler salongbehandlingen til en
      uforglemmelig sensoriske opplevelse.&rdquo;
    `,
      glo: `
      <i><u>Glo Skin Beauty</u></i>
      <br>
      <br>
      &ldquo;Delivering on the promise of healthy, beautiful skin is
      what drives us every day at Glo Skin Beauty. Our innovative skincare and
      nourishing mineral makeup collections work together seamlessly to reveal
      your authentic best. Whether you begin with our skincare or makeup collection – you will experience
      the distinction of our proprietary formulas and the confidence that comes with transformative
      skincare results.
      <br>
      <br>
      At <strong>Glo Skin Beauty</strong>, we believe makeup is an extension of
      skincare and when paired together our skincare and makeup solutions are most powerful. This is
      exactly what inspired the creation of Glo Skin Beauty.&rdquo;
    `,
      cnd: `
      <i><u>CND</u></i>
      <br>
      <br>
      &ldquo;<i>CND is valued globally by the Beauty Professional and their clients as THE
      resource for innovative and reliable nail products and services.</i>
      <br>
      <br>
      We've developed professional products and services since 1979. We expand the promise and possibility of beauty
      for hands and feet! We aim to provide the salon and spa professional with everything they need
      for the highest-quality nail services. This includes products, services, education, and support.
      We’ve been pursuing this goal with unwavering commitment for over thirty years, and we’ve been
      proud to have revolutionized the nail industry in the process.
      <br>
      Each of our products is meticulously researched and tested before it ever reaches the customer.
      We have our own state-of-the-art laboratory and the scientific and technological know-how to
      engineer the exact products that professionals want and need. Once these products and
      services are in their hands, <strong>CND</strong> continues to offer support,
      education, and inspiration.&rdquo;
    `,
      badnor: `
      <i><u>Bad Norwegian</u></i>
      <br>
      <br>
      &ldquo;When <strong>BAD NORWEGIAN</strong> was founded, one thing was
      universally agreed upon; we'd always be a company that places our focus on purity,
      integrity and quality. We decided to never add anything to our products that could
      be considered harmful to the person using it, or to the environment. Ever.
      <br>
      For this reason we created our 'Zero List'- a list of ingredients that we will never use as we
      believe all components must have a necessary function in a product. We will therefore
      not add alcohol, parabens, aluminium, color, mineral oil, silicone or any hormone distorting
      components. We will, however, be very generous with natural goodness and ingredients that
      have been rigorously researched and proven to have a positive effect on the skin.&rdquo;
    `
    };

  tekst(merke: string /* ('elixir' | 'sothys' | 'glo' | 'cnd' | 'badnor') */) {
    return this.merkevareTekst[merke];
  }
}
