import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AktiveBehandlingerService } from '../../services/aktive-behandlinger.service';
import { GlobalService } from '../../services/global.service';
import { ErrorHandteringService } from 'src/app/services/error-handtering.service';

@Component({
  selector: 'slett-behandling',
  templateUrl: './slett-behandling.component.html',
  styleUrls: ['./slett-behandling.component.scss']
})
export class SlettBehandlingComponent extends AktiveBehandlingerService implements OnInit {
  form: FormGroup;

  constructor(
    public db: AngularFirestore,
    fb: FormBuilder,
    public varsel: MatSnackBar,
    _global: GlobalService,
    errorHandtering: ErrorHandteringService
  ) {
    super(db, _global, errorHandtering);

    this.form = fb.group({
      dok_id: ''
    });
  }

  ngOnInit() {
    this.installer_beh();
  }

  forandreBehandling(e0) {
    if (e0.value === '' || !e0.value) { return; }

    this.form.setValue({
      dok_id: e0.value
    });
  }

  slettFormHentCN(cb) {
    return this.form.get(cb);
  }

  _slettBehandling() {
    if (this.form.invalid || this.form.get('dok_id').value === '') {
      return this.varsel.open('Ugyldig form!', '', {
        duration: 2000
      });
    }

    if (confirm(`Slett behandling permanent?`)) {
      const data = this.form.value;

      this.varsel.open('Sletter behandling...', '', {
        duration: 10000
      });

      // Send til Firebase
      this.db.doc(`${this._global.db_behandling_behandlinger}/${data.dok_id}`).delete()
        .then(() => {
          this.varsel.open('Behandling blei slettet', '', {
            duration: 2000
          });
        })
        .catch(error => {
          this.errorHandtering.visError(error, true, false);
        });


    } else { return; }

  }

}
