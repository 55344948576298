import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { MenyComponent } from './meny/meny.component';
import { AdminModule } from './admin/admin.module';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AktiveBehandlingerService } from './services/aktive-behandlinger.service';
import { TenesteComponent } from './teneste/teneste.component';
import { HeimComponent } from './heim/heim.component';
import { FotComponent } from './fot/fot.component';
import { TimebestillingComponent } from './timebestilling/timebestilling.component';
import { OmComponent } from './om/om.component';
import { OverlappFeltComponent } from './overlapp-felt/overlapp-felt.component';
import { MerkevareTekstService } from './services/merkevare-tekst.service';
import { SignaturComponent } from './signatur/signatur.component';
import { MenyMobilComponent } from './meny-mobil/meny-mobil.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { LoggInnComponent } from './logg-inn/logg-inn.component';
import { LoggUtComponent } from './logg-ut/logg-ut.component';
import { ReactiveFormsModule } from '@angular/forms';
import { KontaktComponent } from './kontakt/kontakt.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnarvegAdminComponent } from './snarveg-admin/snarveg-admin.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ErrorHandteringService } from './services/error-handtering.service';
import { Error404Component } from './error404/error404.component';
import { StortBileteComponent } from './stort-bilete/stort-bilete.component';
import { InstagramFeedToComponent } from './program/instagram-feed-to/instagram-feed-to.component';
import { GDPRComponent } from './gdpr/gdpr.component';

@NgModule({
  declarations: [
    AppComponent,
    MenyComponent,
    TenesteComponent,
    HeimComponent,
    FotComponent,
    TimebestillingComponent,
    OmComponent,
    OverlappFeltComponent,
    SignaturComponent,
    MenyMobilComponent,
    ScrollTopComponent,
    LoggInnComponent,
    LoggUtComponent,
    KontaktComponent,
    SnarvegAdminComponent,
    Error404Component,
    StortBileteComponent,
    InstagramFeedToComponent,
    GDPRComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AdminModule,
    MatIconModule,
    MatGridListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AngularFirestore,
    AktiveBehandlingerService,
    MerkevareTekstService,
    ErrorHandteringService,
    ScrollTopComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
