import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TenesteComponent } from './teneste/teneste.component';
import { HeimComponent } from './heim/heim.component';
import { TimebestillingComponent } from './timebestilling/timebestilling.component';
import { OmComponent } from './om/om.component';
import { LoggInnComponent } from './logg-inn/logg-inn.component';
import { AdminComponent } from './admin/admin.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { Error404Component } from './error404/error404.component';
import { StortBileteComponent } from './stort-bilete/stort-bilete.component';
import { GDPRComponent } from './gdpr/gdpr.component';

const routes: Routes = [
  {
    path: 'GDPR',
    component: GDPRComponent
  },
  {
    path: '',
    component: HeimComponent
  },
  {
    path: 'teneste/:teneste',
    component: TenesteComponent
  },
  {
    path: 'teneste',
    component: TenesteComponent,
    redirectTo: ''
  },
  {
    path: 'timebestilling',
    component: TimebestillingComponent
  },
  {
    path: 'kontakt',
    component: KontaktComponent
  },
  {
    path: 'om',
    component: OmComponent
  },
  {
    path: 'logg-inn',
    component: LoggInnComponent
  },
  {
    path: 'admin',
    component: AdminComponent
  },
  { // Stort Instagram-bilete
    path: 'instagram/:id',
    component: StortBileteComponent,
    outlet: 'pop'
  },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
