import { Injectable } from '@angular/core';
import { InstagramFeedPost } from '../../type-vakt'
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstagramFeedToService {
  private currInstagramPoster = new BehaviorSubject<InstagramFeedPost[] | null>(null);
  currInstagramPosterEndretData = this.currInstagramPoster.asObservable();

  testerLokalt = false;

  constructor() { }

  async hentInstagramDataFeed(): Promise<InstagramFeedPost[]> {
    const lokalEllerProd = (): string => {
      if (this.testerLokalt) {
        return 'http://localhost:5001/riaskinbodycare002/us-central1/app001/api/instagram-media';
      } else {
        // Produksjons-link
        return 'https://riaskinbodycare.no/api/instagram-media';
      }
    };

    const instagramData: Response | string = await fetch(lokalEllerProd()).catch(
      (error: Error) => { console.error(error); return error.message; });

    // Hent ut data
    if (typeof instagramData !== 'string') {
      const jsonData = await instagramData.json();

      // Sjekk om det er ein error, og print det ut
      if (jsonData.error && jsonData.error.code) {
        const error: {
          code: number;
          fbtrace_id: string;
          message: string;
          type: string;
        } = jsonData.error;

        console.error('Instagram Error-kode/type:', error.code + ' ' + error.type);
        console.error('Instagram Melding:', error.message);
        console.error('Instagram Trace-ID:', error.fbtrace_id);

        return undefined;
      }

      this.currInstagramPoster.next(jsonData.data);
      return jsonData.data;
    } else { return undefined; }
  }
}
