import { Component, AfterViewInit } from '@angular/core';
import { stokker_om, overlapp } from '../animasjon/animasjoner.animation';
import { InstagramFeedToService } from '../services/annet/instagram-feed-to.service';
import { InstagramFeedPost } from '../type-vakt';

@Component({
  selector: 'app-heim',
  templateUrl: './heim.component.html',
  styleUrls: ['./heim.component.scss'],
  animations: [
    stokker_om,
    overlapp
  ]
})
export class HeimComponent implements AfterViewInit {
  tekstData: string;
  lukk = true;

  instagramDataFeed: InstagramFeedPost[];

  constructor(
    private instagramFeedService: InstagramFeedToService
  ) { }

  async ngAfterViewInit() {
    // Hent Instagram data feed
    this.instagramDataFeed = await this.instagramFeedService.hentInstagramDataFeed();
  }

  skrivTekstData(merke: string) {
    setTimeout(() => {
      this.tekstData = merke;

      this.lukk = false;
      document.querySelector('html').style.overflow = 'hidden';

    }, 50);
  }

  lukk_forandring($event) {
    this.lukk = $event;

    if (this.lukk) { this.tekstData = ''; }
    document.querySelector('html').style.overflow = '';
  }

}
